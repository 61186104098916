import {useEffect, useState} from "react";
import {HaierrsqWarp} from './style';
import HaierrsqTag from './tag';
import {getJson, postJson2, jump} from '../../../libs/HttpUtils';
import {random} from "../../../libs/util";
import {IMG_BOTTOM} from "../../../libs/constant/Haierrsq";
import {parseUrl} from "../../../libs/UrlUtils";

const Haierrsq = (props) => {
    const [model, setModel] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [productImage, setProductImage] = useState('');
    const [brand, setBrand] = useState('');
    const [qaJumpUrl, setQaJumpUrl] = useState('');
    const [tags, setTags] = useState([]);
    const [menuList, setMenuList] = useState([]);

    const brandBg = {
        '卡萨帝': 'https://bbqk.pzjdimg.com/custom/haierrsq/imgs/detail/bg_casarte.png',
        '海尔': 'https://bbqk.pzjdimg.com/custom/haierrsq/imgs/detail/bg_haier.png',
        'Leader': 'https://bbqk.pzjdimg.com/custom/haierrsq/imgs/detail/bg_leader.png'
    };

    useEffect(() => {
        (async () => {
            let url = parseUrl(props.json);
            let {data}= await getJson(url);
            setModel(data['model']);
        })();
    }, [props]);

    useEffect(()=> {
        model && (async () => {
            let {data} = await postJson2("https://waterdoc.haier.net:7000/platformOneCode/oneCode/v2/mainPage", {"model":model});
            let busData = data.data;
            setDeviceName(busData['deviceName'])
            setProductImage(busData['productImage'])
            setBrand(busData['brand'])
            setTags(busData['functionList'])
            setMenuList(busData['menuList'])
            setQaJumpUrl(busData['qaJumpUrl'])
        })();
    }, [model]);

    return (
        <HaierrsqWarp>
            <div className="template-bg">
                {/*<div className="haierrsq-title">{title}</div>*/}
                <div className="haierrsq-wrap" key={random(10)}>
                    <div className="haierrsq-wrap_detail">
                        <div className="haierrsq-wrap_detail_info">
                            <div className="haierrsq-wrap_detail_info_left">
                                <img
                                    alt=""
                                    width="100%"
                                    src={productImage}/>
                            </div>
                            <div className="haierrsq-wrap_detail_info_right">
                                <div className="haierrsq-wrap_detail_info_right_text">
                                    <div>型号：{model}</div>
                                    <div>类型：{deviceName}</div>
                                </div>
                                <img
                                    alt=""
                                    width="100%"
                                    src={brandBg[brand]}/>
                            </div>
                        </div>
                        <div className="haierrsq-wrap_detail_btn">
                            {/*<div className="haierrsq-wrap_detail_btn_wrap"><span className="haierrsq-wrap_detail_btn_text">功能视频</span></div>*/}
                            {/*<div className="haierrsq-wrap_detail_btn_wrap"><span className="haierrsq-wrap_detail_btn_text">电子说明书</span></div>*/}
                            {/*<div className="haierrsq-wrap_detail_btn_wrap"><span className="haierrsq-wrap_detail_btn_text">联网说明</span></div>*/}
                            {
                                menuList && menuList.map((item) => menuBtn(item))
                            }
                        </div>
                    </div>
                    {/*{videoImg &&*/}
                    {/*    <a onClick={() => clickTrigger(videoUrl)} className="haierrsq-wrap_videoDiv">*/}
                    {/*        <img className="haierrsq-wrap_videoImg" src={videoImg} alt="bottom"/>*/}
                    {/*        <img className="haierrsq-wrap_playImg" src={PLAY_BOTTOM} alt="play"/>*/}
                    {/*    </a>*/}
                    {/*}*/}
                    {/*{sliders && 0 < sliders.length && <Slider list={sliders}/>}*/}
                    {tags &&<HaierrsqTag tags={tags}/>}
                </div>
                {qaJumpUrl &&
                    <div className="haierrsq-bottom">
                        <a onClick={() => clickTrigger(qaJumpUrl)}>
                            <img src={IMG_BOTTOM} alt=""/>
                        </a>
                        <div className="block"/>
                    </div>
                }
            </div>
        </HaierrsqWarp>
    )
};

// 点击Slider图触发的事件
const clickTrigger = (url) => {
    jump(url);
};

const menuBtn = (item) => {
    return (
        <div className="haierrsq-wrap_detail_btn_wrap"
             onClick={() => {
                 jump(item['jumpUrl']);
             }}><span className="haierrsq-wrap_detail_btn_text">{item.name}</span></div>
    )
}

export default Haierrsq;


